import React from 'react';
import Modal from 'react-responsive-modal';
import DecadeStory from './decade-story';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
  },
};

const TenStoryModal = ({ modal, setModal, stories }) => {
  function closeModal() {
    setModal(false);
  }

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      onRequestClose={closeModal}
      center
      focusTrapped={false}
      showCloseIcon={false}
      style={customStyles}
      classNames={{
        overlay: 'modal-overlay',
        modal: 'modal-ten',
      }}
    >
      <button
        className="fixed top-0 right-0 m-4 lg:hidden lg:m-10"
        onClick={closeModal}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          className="mx-auto z-10"
        >
          <>
            <path d="M18 6L6 18" />
            <path d="M6 6L18 18" />
          </>
        </svg>
      </button>
      {stories.map((story, index) => (
        <DecadeStory story={story} number={index + 1} key={story.strapiId} />
      ))}
    </Modal>
  );
};

export default TenStoryModal;

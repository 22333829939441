import React from 'react';
import {
  Hero,
  HappyBirthday,
  Playful,
  Decade,
  Time,
  Pioneer,
} from '../components/home';

export default function Home() {
  return (
    <>
      <Hero />
      <HappyBirthday />
      <Playful />
      <Decade />
      <Time />
      <Pioneer />
    </>
  );
}

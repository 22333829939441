import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import VideoPlayer from 'react-video-js-player';
import ScrollTo from '../common/scroll-to';

const Hero = ({ content }) => {
  if (typeof window === 'undefined') {
    return <div />;
  }

  const onReady = (valami) => {
    valami.player().loop(true);
    valami.player().muted(true);
    valami.player().play();
  };

  return (
    <div className="overflow-hidden relative">
      <div className="h-full mt-16">
        <div
          className="flex flex-wrap text-white text-center xxl:pb-10"
          style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
        >
          <div className="w-full p-5 mt-40 mb-20 text-center">
            <h1 className="font-serif text-5xl md:text-6xl">{content.Title}</h1>
            <p className="lg:max-w-3xl mx-auto mt-12 text-xl font-light lg:px-16">
              {content.Subtitle}
            </p>
            <ScrollTo to="video" offset={-200} className="cursor-pointer ">
              <img
                src="/icons/arrow_down.svg"
                width="20"
                className="mx-auto mt-10 transform hover:translate-y-2 duration-200"
                alt="10 things we love most about Trax"
              />
            </ScrollTo>
          </div>
        </div>
      </div>
      <div
        className="hero-video-container"
        style={{
          top: '24px',
          position: 'absolute',
          height: '100%',
          bottom: '50%',
          left: '50%',
          objectFit: 'cover',
          transform: 'translate(-50%)',
          zIndex: '-1',
        }}
      >
        <VideoPlayer
          controls={false}
          autoplay
          src="https://decadeoftrax-images.s3.eu-west-1.amazonaws.com/hero_6533dde447.mov"
          fluid
          onReady={onReady}
        />
      </div>
    </div>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        content: strapiHomePage {
          Hero {
            Subtitle
            Title
          }
        }
      }
    `}
    render={({ content }) => <Hero content={content.Hero} />}
  />
);

Hero.propTypes = {
  content: PropTypes.shape({
    Subtitle: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
};

import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import { Button } from '../common';
import TimeModal from './time/time-modal';

const Time = ({ time, up, content, acq, logo, office }) => {
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState(logo);

  const CATEGORIES = {
    logo,
    acq,
    office,
  };

  const openModalWithCategory = (selectedCategory) => {
    setCategory(selectedCategory);
    setModal(true);
  };

  return (
    <BackgroundImage
      Tag="section"
      id="time"
      className="h-full"
      fluid={time.childImageSharp.fluid}
    >
      <div className="time container pt-64 pb-40 md:pb-32">
        <h2 className="pt-5 px-5">{content.Title}</h2>
        <p className="max-w-4xl pt-6 pb-10 px-5 font-light">
          {content.Subtitle}
        </p>
        <div className="flex flex-col md:flex-row justify-center">
          <div
            onClick={() => openModalWithCategory(CATEGORIES.office)}
            className="bg-purplelight justify-center text-center px-10 py-5 m-3 rounded-md md:w-1/3 cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
          >
            <img
              src="/icons/office.svg"
              width="214"
              className="mx-auto"
              alt="10 things we love most about Trax"
            />
            <p className="text-white mx-auto p-3">Office through time</p>
          </div>
          <div
            onClick={() => openModalWithCategory(CATEGORIES.logo)}
            className="bg-bluelight justify-center text-center px-10 py-5 m-3 rounded-md md:w-1/3 cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
          >
            <img
              src="/icons/logo.svg"
              width="183"
              className="mx-auto"
              alt="10 things we love most about Trax"
            />
            <p className="text-white mx-auto p-3">Logo through time</p>
          </div>
          <div
            onClick={() => openModalWithCategory(CATEGORIES.acq)}
            className="bg-orangepast justify-center text-center px-10 py-5 m-3 rounded-md md:w-1/3 cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl duration-200"
          >
            <img
              src="/icons/acquis.svg"
              width="146"
              className="mx-auto"
              alt="10 things we love most about Trax"
            />
            <p className="text-white mx-auto p-3">Acquisitons through time</p>
          </div>
        </div>
        <div className="pb-40 md:pb-0">
          <div className="flex flex-col md:flex-row justify-center  relative">
            <div className="bg-white justify-center text-center pt-2 pb-5 lg:px-10 w-56 mx-auto my-3 md:m-3 rounded-md md:w-1/4 md:mt-48 h-full">
              <p className="mx-auto p-3 text-5xl text-orangepast">2011-2013</p>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/lego.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  30
                </p>
                <p className="text-xs">Number of employees</p>
              </div>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/world.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  3
                </p>
                <p className="text-xs">Operating countries</p>
              </div>
            </div>
            <div className="bg-white justify-center text-center pt-2 pb-5 lg:px-10 w-56 mx-auto my-3 md:m-3 rounded-md md:w-1/4 md:mt-40 h-full">
              <p className="mx-auto p-3 text-5xl text-orangepast">2014-2016</p>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/lego.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  140
                </p>
                <p className="text-xs">Number of employees</p>
              </div>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/world.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  15
                </p>
                <p className="text-xs">Operating countries</p>
              </div>
            </div>
            <div className="bg-white justify-center text-center pt-2 pb-5 lg:px-10 w-56 mx-auto my-3 md:m-3 rounded-md md:w-1/4 md:mt-32 h-full">
              <p className="mx-auto p-3 text-5xl text-orangepast">2017-2018</p>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/lego.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  250
                </p>
                <p className="text-xs">Number of employees</p>
              </div>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/world.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  50
                </p>
                <p className="text-xs">Operating countries</p>
              </div>
            </div>
            <div className="bg-white justify-center text-center pt-2 pb-5 lg:px-10 w-56 mx-auto my-3 md:m-3 rounded-md md:w-1/4 md:mt-24 h-full">
              <p className="mx-auto p-3 text-5xl text-orangepast">2019-2020</p>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/lego.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  1000
                </p>
                <p className="text-xs">Number of employees</p>
              </div>
              <div className="py-3">
                <p className="text-2xl font-semibold">
                  <img
                    src="/icons/world.svg"
                    width="24"
                    className="inline pb-1 pr-1"
                    alt="employees"
                  />
                  90
                </p>
                <p className="text-xs">Operating countries</p>
              </div>
            </div>
          </div>
          <Img
            className="w-full hidden md:block"
            fluid={up.childImageSharp.fluid}
            alt="fresh"
          />
          <div className="text-center mt-10">
            <Button to="/timeline">VIEW THE FULL COMPANY TIMELINE</Button>
          </div>
        </div>
      </div>
      <TimeModal img={category} modal={modal} setModal={setModal} />
    </BackgroundImage>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        content: strapiHomePage {
          Trax {
            Title
            Subtitle
          }
        }
        time: file(relativePath: { regex: "/time_bg.jpg/" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1442) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        up: file(relativePath: { regex: "/arrow_up.png/" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1442) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        acq: file(
          relativePath: { regex: "/trax-through-time/acqusition.png/" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1064) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        logo: file(relativePath: { regex: "/trax-through-time/logo.png/" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1064) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        office: file(
          relativePath: { regex: "/trax-through-time/office.png/" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1064) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={({ content, time, up, acq, logo, office }) => (
      <Time
        content={content.Trax}
        time={time}
        up={up}
        acq={acq}
        logo={logo}
        office={office}
      />
    )}
  />
);

Time.propTypes = {
  content: PropTypes.shape({
    Subtitle: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
  time: PropTypes.shape({}).isRequired,
  up: PropTypes.shape({}).isRequired,
};

import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Playful = ({ content, hero, game }) => {
  const [show, setShow] = useState(false);

  const showClass = clsx('flex flex-wrap text-center xl:pt-40', {
    'mt-40 mb-20': show,
  });

  return (
    <BackgroundImage
      Tag="section"
      id="game"
      fluid={hero.childImageSharp.fluid}
      className="h-full"
    >
      <div className="container mx-auto pb-40 pt-16 lg:pb-64 relative">
        <div className={showClass}>
          <div className="w-full p-5 mt-20 mb-10 text-center ">
            <h1 className="text-4xl md:text-6xl">{content.Title}</h1>
            <p className="lg:max-w-2xl px-5 mx-auto mt-6 mb-6 font-light">
              {content.Subtitle}
            </p>

            <div className="text-center">
              <button
                type="button"
                onClick={() => setShow(true)}
                className="font-semibold text-base leading-9 py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white"
              >
                HOW TO PLAY?
              </button>
              <a
                href="https://smart-int.trax-cloud.com/home"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to there"
                className="py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white"
              >
                LET’S PLAY!
              </a>
            </div>
          </div>
          {!show ? (
            <p> </p>
          ) : (
            <div className="container">
              <h2>3 simple steps</h2>
              <div className="p-5">
                <div className="flex justify-center">
                  <h2 className="mt-3 mr-2">1.</h2>
                  <p className="mt-10 font-bold">
                    Enter your credentials and choose the box decadeoftrax.{' '}
                  </p>
                </div>
                <p className="max-w-md mx-auto pt-5 px-10 font-light">
                  Username: your Trax email Password: 123456
                </p>
                <p className="max-w-md mx-auto font-light">
                  **If you already have SMART-Int access, use your regular
                  user/password.
                </p>
              </div>
              <div className="p-5">
                <div className="flex justify-center">
                  <h2 className="mt-3 mr-2">2.</h2>
                  <p className="mt-10 font-bold">
                    Recognize the Traxer in the marked box{' '}
                  </p>
                </div>
                <div className="md:flex mt-5 justify-center">
                  <div className="md:w-1/3 my-5 md:mr-10">
                    <p className="text-left">
                      Know who they are? Start typing their name in the search
                      box and see the suggestions
                    </p>
                  </div>
                  <div className="my-auto">
                    <img
                      src="/play/recog1.png"
                      width="400"
                      className=""
                      alt="Tag"
                    />
                  </div>
                </div>
                <div className="md:flex mt-5 justify-center">
                  <div className="md:w-1/3 my-5 md:my-auto md:mr-10">
                    <p className="text-left">
                      Know which region they are from? Select the category
                      SPIRITS, then choose the region and scroll down to find
                      them
                    </p>
                  </div>
                  <div className="my-auto">
                    <img
                      src="/play/recog2.png"
                      width="400"
                      className=""
                      alt="Tag"
                    />
                    <img
                      src="/play/recog3.png"
                      width="400"
                      className="mt-2"
                      alt="Tag"
                    />
                  </div>
                </div>
                <div className="p-5">
                  <div className="flex justify-center">
                    <h2 className="mt-3 mr-2">3.</h2>
                    <p className="mt-10 font-bold">
                      Tag the image and check if you were right!{' '}
                    </p>
                  </div>
                  <p className="mx-auto pt-5font-light">
                    Place the tag on their face and click{' '}
                    <span className="font-bold">CHECK</span> to continue.
                  </p>
                  <img
                    src="/play/tag1.png"
                    width="600"
                    className="mt-5 mb-5 mx-auto"
                    alt="Tag"
                  />
                  <p className="mx-auto pt-5 font-light">You were wrong?</p>
                  <p className="mx-auto pt-5 font-light max-w-lg">
                    No worries, once you click CHECK you will get the correct
                    answer. You will need to{' '}
                    <span className="font-bold">UNTAG ERRORS</span>, then tag
                    the correct name to continue to the next image
                  </p>
                  <img
                    src="/play/tag2.png"
                    width="600"
                    className="mt-5 mx-auto"
                    alt="Tag"
                  />
                </div>
              </div>
              <div className="text-center mb-10">
                {/* <button
                  type="button"
                  onClick={() => setShow(false)}
                  className="py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white font-semibold text-base leading-9"
                >
                  Close
                </button> */}
                <a
                  href="https://smart-int.trax-cloud.com/home"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Go to there"
                  className="py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white"
                >
                  LET’S PLAY!
                </a>
              </div>
            </div>
          )}
        </div>
        {!show ? (
          <div>
            <Img
              className="mx-auto md:max-w-sm lg:max-w-2xl xl:max-w-4xl xxl:max-w-5xl"
              fluid={game.childImageSharp.fluid}
              alt="fresh"
            />
            <img
              src="/icons/birthday.svg"
              width="400"
              className="hidden lg:block absolute right-0 bottom-0 lg:mb-10 xl:mb-20 xxl:mr-40"
              alt="Birthday"
            />
          </div>
        ) : (
          <p />
        )}
      </div>
    </BackgroundImage>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        content: strapiHomePage {
          Playful {
            Subtitle
            Title
          }
        }
        hero: file(relativePath: { regex: "/playful_bg.jpg/" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1442) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        game: file(relativePath: { regex: "/game.png/" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1064) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={({ content, hero, game }) => (
      <Playful content={content.Playful} hero={hero} game={game} />
    )}
  />
);

Playful.propTypes = {
  content: PropTypes.shape({
    Subtitle: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
  hero: PropTypes.shape({}).isRequired,
  game: PropTypes.shape({}).isRequired,
};

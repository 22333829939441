import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import TenStoryModal from './decade/ten-story-modal';
import DecadeTile from './decade/decade-tile';

const CATEGORIES = {
  HowWeJoined: 'HowWeJoined',
  ToughDays: 'ToughDays',
  Achievements: 'Achievements',
  Lessons: 'Lessons',
  CustomerStories: 'CustomerStories',
  Love: 'Love',
  Moments: 'Moments',
};

const Decade = ({ content, stories }) => {
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState(CATEGORIES.Achievements);

  const openModalWithCategory = (selectedCategory) => {
    setCategory(selectedCategory);
    setModal(true);
  };

  const selectedStories = stories[category];

  return (
    <section id="decade" className="lg:pb-20">
      <div className="container text-left mt-20 mb-10 px-5">
        <h1>{content.Title}</h1>
        <p className="pt-6 font-light">{content.Subtitle}</p>
      </div>
      <div className="decade lg:flex max-h-full font-normal xxl:container xxl:mx-auto xxl:px-10">
        <div className="lg:flex-col lg:w-3/12 ">
          <DecadeTile
            onClick={() => openModalWithCategory(CATEGORIES.HowWeJoined)}
            className="stories bg-purplelight lg:mb-6"
          >
            <img src="/icons/story.svg" width="279" alt="Birthday" />
            <p className="mx-auto p-3 text-white">
              10 stories how we joined Trax
            </p>
          </DecadeTile>
          <DecadeTile
            onClick={() => openModalWithCategory(CATEGORIES.ToughDays)}
            className="tough bg-bluelight"
          >
            <img src="/icons/tough.svg" width="299" alt="Birthday" />
            <p className="max-w-56 mx-auto p-3 text-white">
              10 tough days we overcame
            </p>
          </DecadeTile>
        </div>
        <TenStoryModal
          modal={modal}
          setModal={setModal}
          stories={selectedStories}
        />

        {!show ? (
          <div className="lg:hidden text-center">
            <button
              type="button"
              onClick={() => setShow(true)}
              className="py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white"
            >
              View All
            </button>
          </div>
        ) : (
          <div className="lg:flex-row lg:w-9/12 lg:hidden">
            <div className="lg:flex">
              <div className="lg:flex-col lg:w-2/3">
                <DecadeTile
                  onClick={() => openModalWithCategory(CATEGORIES.Moments)}
                  className="bg-bluelight"
                >
                  <img src="/icons/favorite.svg" width="324" alt="Birthday" />
                  <p className="mx-auto p-3 text-white">
                    10 favourite moments in Trax history
                  </p>
                </DecadeTile>
                <div className="lg:flex">
                  <DecadeTile
                    onClick={() =>
                      openModalWithCategory(CATEGORIES.Achievements)
                    }
                    className="bg-orangepast"
                  >
                    <img src="/icons/biggest.svg" width="324" alt="Birthday" />
                    <p className="max-w-40 mx-auto p-3 text-white">
                      10 biggest achievements
                    </p>
                  </DecadeTile>
                  <div
                    onClick={() => openModalWithCategory(CATEGORIES.Lessons)}
                    className="bg-yellowpast m-3"
                  >
                    <img
                      src="/icons/lesson.svg"
                      width="324"
                      alt="Birthday"
                      className="mx-auto"
                    />
                    <p className="text-white mx-auto text-center p-3">
                      10 lessons we learnt along the way
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:flex-col lg:w-1/3">
                <DecadeTile
                  onClick={() =>
                    openModalWithCategory(CATEGORIES.CustomerStories)
                  }
                  className="bg-orangepast"
                >
                  <img src="/icons/most.svg" width="324" alt="Birthday" />
                  <p className="max-w-40 mx-auto p-3 text-white">
                    10 most memorable customer stories
                  </p>
                </DecadeTile>
                <div
                  onClick={() => openModalWithCategory(CATEGORIES.Love)}
                  className="bg-purplelight m-3"
                >
                  <img
                    src="/icons/things.svg"
                    width="324"
                    alt="Birthday"
                    className="mx-auto"
                  />
                  <p className="text-white max-w-40 mx-auto text-center p-3">
                    10 things we love most about Trax
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:hidden text-center">
              <button
                type="button"
                onClick={() => setShow(false)}
                className="py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white"
              >
                Close
              </button>
            </div>
          </div>
        )}
        <div className="hidden lg:block lg:flex-row lg:w-9/12">
          <div className="lg:flex">
            <div className="lg:flex-col lg:w-2/3">
              <DecadeTile
                onClick={() => openModalWithCategory(CATEGORIES.Moments)}
                className="favorite bg-bluelight"
              >
                <img src="/icons/favorite.svg" width="324" alt="Birthday" />
                <p className="mx-auto p-3 text-white">
                  10 favourite moments in Trax history
                </p>
              </DecadeTile>
              <div className="lg:flex">
                <DecadeTile
                  onClick={() => openModalWithCategory(CATEGORIES.Achievements)}
                  className="biggest bg-orangepast"
                >
                  <img src="/icons/biggest.svg" width="274" alt="Birthday" />
                  <p className="max-w-40 mx-auto p-3 text-white">
                    10 biggest achievements
                  </p>
                </DecadeTile>
                <DecadeTile
                  onClick={() => openModalWithCategory(CATEGORIES.Lessons)}
                  className="lessons bg-yellowpast"
                >
                  <img src="/icons/lesson.svg" width="239" alt="Birthday" />
                  <p className="text-white mx-auto p-3">
                    10 lessons we learnt along the way
                  </p>
                </DecadeTile>
              </div>
            </div>
            <div className="lg:flex-col lg:w-1/3">
              <DecadeTile
                onClick={() =>
                  openModalWithCategory(CATEGORIES.CustomerStories)
                }
                className="most bg-orangepast md:mb-6"
              >
                <img src="/icons/most.svg" width="343" alt="Birthday" />
                <p className="max-w-40 mx-auto p-3 text-white">
                  10 most memorable customer stories
                </p>
              </DecadeTile>
              <DecadeTile
                onClick={() => openModalWithCategory(CATEGORIES.Love)}
                className="things bg-purplelight"
              >
                <img src="/icons/things.svg" width="210" alt="Birthday" />
                <p className="text-white max-w-40 mx-auto p-3">
                  10 things we love most about Trax
                </p>
              </DecadeTile>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        content: strapiHomePage {
          TopTens {
            Subtitle
            Title
          }
        }
        stories: strapiDecadeStories {
          Achievements {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
          CustomerStories {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
          HowWeJoined {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
          Lessons {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
          Love {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
          Moments {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
          ToughDays {
            strapiId: id
            content
            employee: employees {
              id
              name
              position
            }
          }
        }
      }
    `}
    render={({ content, stories }) => (
      <Decade content={content.TopTens} stories={stories} />
    )}
  />
);

Decade.propTypes = {
  content: PropTypes.shape({
    Subtitle: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
};

import React from 'react';
import clsx from 'clsx';

const style =
  'flex flex-col items-center justify-center text-center m-3 cursor-pointer transform hover:-translate-y-1 hover:shadow-2xl p-6 duration-200';

const DecadeTile = ({ children, onClick, className }) => (
  <div onClick={onClick} className={clsx(style, className)}>
    {children}
  </div>
);

export default DecadeTile;

import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import TraxersList from '../traxers/traxers-list';
import { Button } from '../common';

const Pioneer = ({ content, traxers }) => {
  return (
    <section id="pioneering" className="container mt-20 mb-20">
      <div className="border-b border-orangepast md:flex mb-5 px-5">
        <div className="w-full md:w-8/12">
          <h2 className="text-4xl md:text-5xl"> {content.Title}</h2>
          <p className="pt-6 font-light"> {content.Subtitle}</p>
        </div>
        <div className="w-full md:w-4/12 text-right pt-10 md:pt-40 lg:pt-20">
          <Link
            to="/pioneering-traxers"
            className="text-orangepast border-b border-orangepast"
          >
            VIEW ALL STORIES
            <img
              src="/icons/arrow_orange.svg"
              width="20"
              className="inline pl-2 pb-1"
              alt="10 things we love most about Trax"
            />
          </Link>
        </div>
      </div>
      <TraxersList traxers={traxers.slice(0, 3)} />
      <div className="flex justify-center">
        <Button to="/pioneering-traxers">View more stories</Button>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        section: strapiHomePage {
          Pioneering {
            Subtitle
            Title
          }
        }
        traxers: allStrapiPioneeringTraxers(
          sort: { order: DESC, fields: updated_at }
        ) {
          nodes {
            title
            content
            lead
            strapiId
            updated_at(formatString: "YYYY-MM-DD")
            employee {
              id
              name
              position
            }
            thumbnail {
              childImageSharp {
                fixed(height: 250) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={({ section, traxers }) => (
      <Pioneer content={section.Pioneering} traxers={traxers.nodes} />
    )}
  />
);

Pioneer.propTypes = {
  content: PropTypes.shape({
    Subtitle: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
};

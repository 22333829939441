import React from 'react';
import clsx from 'clsx';
import { Employee } from '../../common';

const DecadeStory = ({ number, story }) => {
  const { content, title, employee } = story;
  const storyClass = clsx(
    'bg-white max-w-4xl p-5 md:flex mx-auto rounded-xl mb-20',
    { 'flex-row-reverse': number % 2 === 0 }
  );

  return (
    <div>
      <h1 className="text-center mt-10 mb-10">{title}</h1>
      <div className={storyClass}>
        <p className="items-start text-purple text-6xl p-5 m-5 font-bold">
          {number}
        </p>
        <div className="m-5">
          <p>{content}</p>
          <div className="mt-4">
            <Employee employee={employee} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DecadeStory;

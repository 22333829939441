import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import VideoThumbnail from './happy-birthday/video-thumbnail';
import Button from '../common/button';

const HappyBirthday = ({ content, videos }) => {
  if (typeof window === 'undefined') {
    return <div />;
  }

  return (
    <section id="videos" className="container mt-20 mb-20">
      <div className="border-b border-orangepast md:flex mb-5">
        <div className="w-full md:w-8/12 px-5">
          <h2 className="text-4xl md:text-5xl">{content.Title}</h2>
          <p className="pt-6 font-light">{content.Subtitle}</p>
        </div>
        <div className="w-full md:w-4/12 text-right pt-10 md:pt-40 lg:pt-20">
          <Link
            to="/videos"
            className="text-orangepast border-b border-orangepast"
          >
            VIEW ALL VIDEOS
            <img
              src="/icons/arrow_orange.svg"
              width="20"
              className="inline pl-2 pb-1 transform hover:translate-x-1 duration-200"
              alt="10 things we love most about Trax"
            />
          </Link>
        </div>
      </div>
      <div className="lg:flex">
        <div className="video px-5 pb-2 h-full lg:py-5 lg:w-3/4">
          <VideoThumbnail
            className="lg:h-128"
            src={videos[0].video[0].url}
            employee={videos[0].employee}
          />
        </div>
        <div className="flex-col px-5 lg:py-5 lg:w-1/4">
          {videos.slice(1, 4).map(({ video, id, employee }) => (
            <VideoThumbnail
              key={id}
              employee={employee}
              src={video[0].url}
              small
            />
          ))}
        </div>
      </div>
      <div className="text-center">
        <Button to="/videos" className="mx-auto">
          VIEW ALL VIDEOS
        </Button>
      </div>
    </section>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        content: strapiHomePage {
          HappyBirthday {
            Title
            Subtitle
          }
        }
        videos: allStrapiVideos {
          nodes {
            EmployeeVideo {
              id
              video {
                url
              }
              employee {
                id
                name
                position
              }
            }
          }
        }
      }
    `}
    render={({ content, videos }) => (
      <HappyBirthday
        content={content.HappyBirthday}
        videos={videos.nodes[0].EmployeeVideo.slice(0, 4)}
      />
    )}
  />
);

HappyBirthday.propTypes = {
  content: PropTypes.shape({
    Subtitle: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
  }).isRequired,
};
